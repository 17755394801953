
<template>
    <div>
        <vue-progress-bar></vue-progress-bar>
        <CContainer v-if="loaded">
            <br>
            <CRow>
                <CCol lg="12">
                    <CCard>
                        <CCardBody>
                            <CRow>
                                <CCol lg="12">
                                    <table style="width:100%">
                                        <tr>
                                            <td>Name:</td>
                                            <th>{{document_details.downloaded_by}}</th>
                                        </tr>
                                        <tr>
                                            <td>Date:</td>
                                            <th>{{this.changeDateFormat(document_details.downloaded_at)}}</th>
                                        </tr>
                                        <tr>
                                            <td>Status:</td>
                                            <th>
                                                <CBadge :color="getColorStatus(getDocumentStatus(document_details.document_status))">
                                                    {{this.getDocumentStatus(document_details.document_status).toUpperCase()}}
                                                </CBadge>
                                            </th>
                                        </tr>
                                    </table>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
                
                <CTabs :active-tab="child_tab" @update:activeTab="changeTab">
                    <CTab title="Document Routing">
                        <br>
                        <CRow>
                            <CCol lg="6">
                                <CCard>
                                    <CCardHeader><font-awesome-icon icon="stream"/>  Document Timeline
                                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                                    </CCardHeader>
                                    <CCardBody>
                                        <CRow>
                                            <CCol lg="12">
                                                <a-timeline>
                                                    <a-timeline-item
                                                        v-for="(routing, parent_index) in document_timeline.slice().reverse()" 
                                                        v-bind:key="parent_index"
                                                        :color="getColorStatus(routing.status)">
                                                        <template v-if="routing.status == 't-past'">
                                                            {{routing.date}} <br>
                                                            {{routing.message}} <br><br>
                                                            <CRow>
                                                                <CCol lg="6" v-if="routing.requirements.length > 0">
                                                                    <span v-if="routing.requirements.length > 0">Signature Requirements<br></span>
                                                                    <span v-for="(requirement, past_index) in routing.requirements" v-bind:key="requirement.id">
                                                                        {{past_index+1}}. {{requirement.requirement}} <br>
                                                                    </span>
                                                                </CCol>
                                                                <CCol lg="6" v-if="routing.send_back_remarks.length > 0">
                                                                    <span v-if="routing.send_back_remarks.length > 0">Send Back Remarks<br></span>
                                                                    <span v-for="(remark, past_remark_index) in routing.send_back_remarks" v-bind:key="remark.id">
                                                                        {{past_remark_index+1}}. {{remark.reason}}, {{changeDateFormat(remark.created_at)}} <br>
                                                                    </span>
                                                                </CCol>
                                                            </CRow>
                                                            <!-- <span v-if="routing.requirements.length > 0">Signature Requirements<br></span>
                                                            <span v-for="(requirement, past_index) in routing.requirements" v-bind:key="requirement.id">
                                                                {{past_index+1}}. {{requirement.requirement}} <br>
                                                            </span> -->
                                                        </template>
                                                        <template v-if="routing.status == 't-cancelled'">
                                                            <span v-if="routing.date" style="color: #e25f5f">{{routing.date}} <br></span>
                                                            <span style="color: #e25f5f">{{routing.message}}</span> <br>
                                                            <i v-if="routing.remarks" style="color: #e25f5f">Reason/Remarks: {{routing.remarks}}<br><br></i> 
                                                            <span style="color: #e25f5f" v-if="routing.requirements.length > 0">Signature Requirements <br></span>
                                                            <span style="color: #e25f5f" v-for="(requirement, future_index) in routing.requirements" v-bind:key="requirement.id">
                                                                {{future_index+1}}. {{requirement.requirement}} <br>
                                                            </span>
                                                        </template>
                                                        <template v-if="routing.status == 't-present'">
                                                            <span v-if="routing.date" style="color: #4caf50">{{routing.date}} <br></span>
                                                            <span style="color: #4caf50"><strong>{{routing.message}}</strong></span> <br><br>
                                                            <CRow>
                                                                <CCol lg="6">
                                                                    <span style="color: #4caf50" v-if="routing.requirements.length > 0">Signature Requirements<br></span>
                                                                    <span style="color: #4caf50" v-for="(requirement, present_index) in routing.requirements" v-bind:key="requirement.id">
                                                                        {{present_index+1}}. {{requirement.requirement}} <br>
                                                                    </span>
                                                                </CCol>
                                                                <CCol lg="6">
                                                                    <span style="color: #4caf50" v-if="routing.send_back_remarks.length > 0">Send Back Remarks<br></span>
                                                                    <span style="color: #4caf50" v-for="(remark, past_remark_index) in routing.send_back_remarks" v-bind:key="remark.id">
                                                                        {{past_remark_index+1}}. {{remark.reason}}, {{changeDateFormat(remark.created_at)}} <br>
                                                                    </span>
                                                                </CCol>
                                                            </CRow>
                                                            <!-- <span style="color: #4caf50" v-if="routing.requirements.length > 0">Signature Requirements<br></span>
                                                            <span style="color: #4caf50" v-for="(requirement, present_index) in routing.requirements" v-bind:key="requirement.id">
                                                                {{present_index+1}}. {{requirement.requirement}} <br>
                                                            </span> -->
                                                        </template>
                                                        <template v-if="routing.status == 't-future'">
                                                            <span v-if="routing.date">{{routing.date}} <br></span>
                                                            <span style="color: #a39e9e">{{routing.message}}</span><br><br>
                                                            <CRow>
                                                                <CCol lg="6">
                                                                    <span style="color: #a39e9e" v-if="routing.requirements.length > 0">Signature Requirements<br></span>
                                                                    <span style="color: #a39e9e" v-for="(requirement, future_index) in routing.requirements" v-bind:key="requirement.id">
                                                                        {{future_index+1}}. {{requirement.requirement}} <br>
                                                                    </span>
                                                                </CCol>
                                                                <CCol lg="6">
                                                                    <span style="color: #a39e9e" v-if="routing.send_back_remarks.length > 0">Send Back Remarks<br></span>
                                                                    <span style="color: #a39e9e" v-for="(remark, future_remark_index) in routing.send_back_remarks" v-bind:key="remark.id">
                                                                        {{future_remark_index+1}}. {{remark.reason}}, {{changeDateFormat(remark.created_at)}} <br>
                                                                    </span>
                                                                </CCol>
                                                            </CRow>
                                                            <!-- <span style="color: #a39e9e" v-if="routing.requirements.length > 0">Signature Requirements<br></span>
                                                            <span style="color: #a39e9e" v-for="(requirement, future_index) in routing.requirements" v-bind:key="requirement.id">
                                                                {{future_index+1}}. {{requirement.requirement}} <br>
                                                            </span> -->
                                                        </template>
                                                    </a-timeline-item>
                                                </a-timeline>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                            <CCol lg="6">
                                <CCard>
                                    <CCardHeader><font-awesome-icon icon="file-download"/>  Document Download/View Details
                                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                                    </CCardHeader>
                                    <CCardBody>
                                        <CRow>
                                            <CCol lg="12">
                                                <CDataTable
                                                    :items="document_details.document_routing_download_details"
                                                    :fields="download_details_fields"
                                                    border
                                                    :items-per-page="10"
                                                    sorter
                                                    pagination
                                                    size="sm"
                                                >
                                                <template #created_at={item}>
                                                    <td>
                                                        <center>
                                                            {{changeDateFormat(item.created_at)}}
                                                        </center>
                                                    </td>
                                                </template>
                                                <template #document_status={item}>
                                                    <td>
                                                        <center>
                                                        <CBadge :color="getColorStatus(getDocumentStatus(item.document_status))">
                                                            {{getDocumentStatus(item.document_status).toUpperCase()}}
                                                        </CBadge>
                                                        </center>
                                                    </td>
                                                </template>
                                                </CDataTable>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow>
                    </CTab>
                    <CTab title="Form Application">
                        <br>
                        <CCard>
                            <CCardBody v-if="form_data_preview_loaded">
                                <CRow>
                                    <CCol lg="12">
                                        <FormDataPreview ref="_formDataPreview"/>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                            
                            <CCardBody v-else>
                                <h5>No form application found!</h5>
                            </CCardBody>

                        </CCard>
                    </CTab>
                </CTabs>

        </CContainer>
  </div>
</template>
<script>
import FormManagementJS from '../form-management/js/form-management.js';
import FormDataPreview from '../form-management/form-data/components/FormDataPreview.vue'
export default {
    name: 'DocumentRoutingFileViewer',
    components: {
        FormDataPreview
    },
    data() {
        return {
            download_details_fields: [
                { key: 'downloaded_by', label: 'Name', sorter: false,},
                { key: 'created_at', label: 'Downloaded/Preview Date'},
                { key: 'document_status', label: 'Document Status', sorter: false,},
            ],
            organization_key: null,
            document_details: null,
            document_timeline: [],
            loaded: false,
            form_data: null,
            child_tab: 0,
            form_data_preview_loaded: false,
        }
    },
    created() {
        this.getOrganization();
    },
    methods: {
        changeTab: function(item) {
            this.child_tab = item
            item ? this.generatePDFPreview() : ''
        },
        generatePDFPreview: function () {
            if(this.form_data) {
                let rowCounter = 0;
                this.form_data_preview_loaded = true;
                for (let index = 0; index < this.form_data.form_detail_fields.length; index++) {
                    const element = this.form_data.form_detail_fields[index];
                    if(element.form_detail_field_value.length > rowCounter) {
                        rowCounter = element.form_detail_field_value.length
                    }
                }
                console.log(rowCounter)
                this.$nextTick(() => {
                    this.$refs._formDataPreview.control_number = `Ref. No.: ${this.document_details.reference_number}`
                    this.$refs._formDataPreview.detailsRowCount = rowCounter;
                    this.$refs._formDataPreview.organization_logo = this.$store.getters.getOrganizationLogo;
                    this.$refs._formDataPreview.header_fields = FormManagementJS.generateHeaderFooterField(this.form_data.form_header_footer_fields, 'header');
                    this.$refs._formDataPreview.detail_fields = FormManagementJS.generateDetailField(this.form_data.form_detail_fields);
                    this.$refs._formDataPreview.footer_fields = FormManagementJS.generateHeaderFooterField(this.form_data.form_header_footer_fields, 'footer');

                    this.$refs._formDataPreview.header_footer_field_values = FormManagementJS.generateHeaderFooterFieldValue(this.form_data.form_header_footer_fields);
                    this.$refs._formDataPreview.detail_field_values = FormManagementJS.generateDetailFieldValue(this.form_data.form_detail_fields);
                })
            }
        },
        getOrganization: function () {
            axios.get(process.env.VUE_APP_BACKEND_URL+'/organization/'+this.$route.params.orgName, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.organization_key = response.data.data.org_key
                }
            })
        },
        getDocumentDetails: function() {
            this.$Progress.start()
            axios.post(`${this.$backend_url_with_auth}/document-details`,
                { id: this.$route.params.id, download_reference_number: this.$route.params.reference_number },
                { headers: {'Organization-Key': this.organization_key, 'Organization-Name': this.$route.params.orgName } },
                { validateStatus: () => true }
            ).then(response => {
                if(response.status == 200) {
                    this.assignData(response.data.data)
                }
            }).catch(error => {
                if(error.response.data.message == 'Validation Error.') 
                {
                    let x = []
                    for (let i = 0; i < error.response.data.errors.length; i++) {
                        x.push(`<li>${error.response.data.errors[i]}</li>`)
                    }
                    Vue.swal({
                        icon: "error",
                        title: "Validation Error",
                        html: `<ul style="text-align: left;">${x.join('')}</ul>`, 
                    })
                }
            })
        },
        assignData: function(data) {
            this.form_data = data.form_data;
            delete data.form_data;
            this.document_details = data;
            this.document_timeline = [];
            // DOCUMENT TIMELINE
            let _created = {
                date: `${this.changeDateFormat(this.document_details.created_at)}`,
                message: `Document Created by ${this.document_details.user.first_name} ${this.document_details.user.last_name}`,
                status: 't-past',
                requirements: [],
                send_back_remarks: [],
            }
            let _started = {
                date: `${this.changeDateFormat(this.document_details.started_at)}`,
                message: `Document Routing Started by ${this.document_details.user.first_name} ${this.document_details.user.last_name}`,
                status: 't-past',
                requirements: [],
                send_back_remarks: [],
            }
            
            this.document_timeline.push(_created)
            this.document_timeline.push(_started)

            
            let activity = this.document_details.document_routing_timeline_views

            let signatory_counter=0;
            if(activity.length > 0 ){ 
                for (let i = 0; i < activity.length; i++) {
                    if(activity[i].signed_at) signatory_counter++;
                    let _row = {
                        message: `${activity[i].messages}`,
                        requirements: activity[i].document_signature_requirements,
                        send_back_remarks: activity[i].document_routing_sendback_reasons,
                    }
                    if(activity[i].status == 1) {
                        _row.date = `${activity[i].signed_at ? this.changeDateFormat(activity[i].signed_at) : ''}`,
                        _row.status = 't-future'
                    } else if (activity[i].status == 2) {
                        _row.date = `${activity[i].signed_at ? this.changeDateFormat(activity[i].signed_at) : ''}`,
                        _row.status = 't-present'
                    } else if (activity[i].status == 3) {
                        _row.date = `${activity[i].signed_at ? this.changeDateFormat(activity[i].signed_at) : ''}`,
                        _row.status = 't-past'
                    } else if (activity[i].status == 4) {
                        _row.date = `${this.changeDateFormat(this.document_details.updated_at)}`,
                        _row.remarks = activity[i].remarks
                        _row.status = 't-cancelled'
                    }
                    this.document_timeline.push(_row)
                }
            }

            let _completion = {
                date: '',
                message: this.document_details.status != 4 ? 'Document Completed.' : 'Document Cancelled.',
                status:  this.document_details.status == 4 ? 't-cancelled-routing' : this.document_details.status == 3 ? 't-present' : signatory_counter == activity.length ? 't-present' : 't-future',
                requirements: [],
                send_back_remarks: [],
            }

            if(signatory_counter == activity.length) {
                _completion.date = `${this.changeDateFormat(this.document_details.updated_at)}`
            }

            this.document_timeline.push(_completion)
            // DOCUMENT TIMELINE END.


            this.loaded = true;
            this.$Progress.finish()
        }
    },
    watch: {
        organization_key: function(value) {
            if(value) {
                this.getDocumentDetails();
            }
        }
    }
}
</script>